export interface SerializedCrmEntryOrder {
    id: string;
    created_at: string;
}

export default class CrmEntryOrder {
    private readonly _id: string;
    private readonly _createdAt: Date;

    constructor(props: SerializedCrmEntryOrder) {
        this._id = props.id;
        this._createdAt = new Date(props.created_at);
    }

    get id(): string {
        return this._id;
    }

    get createdAt(): Date {
        return this._createdAt;
    }
}
