import { AxiosResponse } from "axios";
import Axios from "./axios";

class CrmEntry {
    private axios: typeof Axios;

    constructor(axios: typeof Axios) {
        this.axios = axios;
    }

    adminIndex(): Promise<AxiosResponse> {
        return this.axios.get("/admin/crm-entries");
    }

    adminDeleteComment(
        crmEntryId: string,
        commentId: string
    ): Promise<AxiosResponse> {
        return this.axios.delete(
            `/admin/crm-entries/${crmEntryId}/comments/${commentId}`
        );
    }

    adminStoreComment(
        crmEntryId: string,
        comment: string,
        captcha: string
    ): Promise<AxiosResponse> {
        return this.axios.post(`/admin/crm-entries/${crmEntryId}/comments`, {
            comment,
            captcha,
        });
    }

    adminSnooze(crmEntryId: string): Promise<AxiosResponse> {
        return this.axios.put(`/admin/crm-entries/${crmEntryId}/snooze`);
    }
}

export default new CrmEntry(Axios);
