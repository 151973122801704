export interface SerializedCrmEntryValueHits {
    value: string;
    hits: number;
}

export default class CrmEntryValueHits {
    private readonly _value: string;
    private readonly _hits: number;

    constructor(props: SerializedCrmEntryValueHits) {
        this._value = props.value;
        this._hits = props.hits;
    }

    get value(): string {
        return this._value;
    }

    get hits(): number {
        return this._hits;
    }
}
