import { VChip } from 'vuetify/lib/components/VChip';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_c('div',[_c(VChip,{attrs:{"label":"","color":_vm.chipColor}},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.total)?_c('span',[_vm._v("("+_vm._s(_vm.total)+")")]):_vm._e()])],1)]),(_vm.isLoading)?_c(VExpansionPanelContent,_vm._l((Array(4)),function(val){return _c(VSkeletonLoader,{key:val,staticClass:"mb-4",attrs:{"type":"article"}})}),1):_c(VExpansionPanelContent,_vm._l((_vm.crmEntries),function(crmEntry){return _c('crm-entry-card',{key:crmEntry.id,staticClass:"mb-2",attrs:{"crm-entry":crmEntry},on:{"reload":_vm.reload}})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }