










import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class CrmCategoryLogo extends Vue {
    @Prop({ required: true, type: String }) src!: string;
}
