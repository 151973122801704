import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"disabled":_vm.disabled,"text":_vm.text,"exact":"","small":_vm.small,"color":"error","depressed":!_vm.text,"block":_vm.block}},'v-btn',attrs,false),on),[(_vm.withIcon)?_c(VIcon,[_vm._v(_vm._s(_vm.deleteIcon))]):_vm._e(),_vm._v(" Delete ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._t("title",function(){return [_vm._v(" Confirm Delete ")]})],2),_c(VCardText,[_vm._t("body",function(){return [_vm._v(" Are you sure you want to delete this "+_vm._s(_vm.itemText)+"? ")]})],2),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" No ")]),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":_vm.emitConfirmed}},[_vm._v(" Yes ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }