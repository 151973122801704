export interface SerializedCrmEntryComment {
    id: string;
    comment: string;
    timestamp: string;
    author: string;
}

export default class CrmEntryComment {
    private readonly _id: string;
    private readonly _timestamp: Date;
    private readonly _comment: string;
    private readonly _author: string;

    constructor(props: SerializedCrmEntryComment) {
        this._id = props.id;
        this._timestamp = new Date(props.timestamp);
        this._comment = props.comment;
        this._author = props.author;
    }

    get id(): string {
        return this._id;
    }

    get timestamp(): Date {
        return this._timestamp;
    }

    get comment(): string {
        return this._comment;
    }

    get author(): string {
        return this._author;
    }
}
